import { ProtectedRouteGroup, ProtectedRouteLink } from 'components/elements';
import {
    ROLE_ADMINISTRATOR,
    ROLE_ASSESSMENT_ASSESSOR,
    ROLE_ASSESSMENT_REVIEWER,
    ROLE_CLIENT_ADMINISTRATOR,
    ROLE_CLIENT_USER,
    ROLE_CLIENT_VIEW_ONLY,
    ROLE_COMPANY_ADMINISTRATOR,
    ROLE_PROSURE_CLIENT_SERVICES,
    ROLE_REGISTRATIONS_RENEWALS,
} from 'constants/role';
import { isAdmin } from 'helpers/helpers';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Response } from 'service';

const ProsureMenu = (props) => {
    const menuRef = useRef();
    const [menuType, setMenuType] = useState('');
    const tasksCount = useSelector((state) => {
        return state.tasks?.tasksCount ?? 0;
    });

    const rootResponse = useSelector((state) => state.root.response);

    useEffect(() => {
        let classList = menuRef.current.classList;
        props.show ? classList.add('show') : classList.remove('show');

        if (props.type !== menuType) {
            setMenuType(props.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        // on load, register hover
        menuRef.current.addEventListener('mouseenter', () => {
            typeof props.onMenuOpen === 'function' && props.onMenuOpen();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMenuType = () => {
        return menuType === 'desktop' ? '--desktop-menu' : '--mobile-menu';
    };

    const getCafmStatsLink = () => {
        if (
            !(
                Response.getLink(rootResponse, 'client-elogs-integration') ||
                isAdmin()
            )
        ) {
            return null;
        }

        return (
            <ProtectedRouteLink
                route="/supply-chain/elogs-cafm-stats"
                label="Elogs CAFM Stats"
                roleRestriction={[
                    ROLE_ADMINISTRATOR,
                    ROLE_CLIENT_ADMINISTRATOR,
                    ROLE_CLIENT_VIEW_ONLY,
                    ROLE_CLIENT_USER,
                ]}
            />
        );
    };

    return (
        <nav
            className={'menu prosure-menu ' + getMenuType()}
            ref={menuRef}
            aria-labelledby="main Prosure menu"
            onClick={props.onClick}
        >
            <ul>
                <ProtectedRouteLink route="/dashboard" />
                <ProtectedRouteLink
                    route="/client-bio"
                    label="Company Bio"
                    roleRestriction={[
                        ROLE_CLIENT_USER,
                        ROLE_CLIENT_ADMINISTRATOR,
                    ]}
                />
                <ProtectedRouteLink
                    route="/tasks"
                    label="Task List"
                    count={tasksCount}
                    roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                />
                <ProtectedRouteLink
                    route="/service-providers"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                        ROLE_REGISTRATIONS_RENEWALS,
                        ROLE_ASSESSMENT_ASSESSOR,
                        ROLE_ASSESSMENT_REVIEWER,
                        ROLE_CLIENT_ADMINISTRATOR,
                        ROLE_CLIENT_VIEW_ONLY,
                        ROLE_CLIENT_USER,
                    ]}
                />

                <ProtectedRouteLink
                    route="/supply-chains"
                    label="Supply Chains"
                    roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                >
                    <ProtectedRouteGroup
                        roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                    >
                        <ProtectedRouteLink
                            route="/supply-chains/active"
                            label="Active Clients"
                            roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                        />
                        <ProtectedRouteLink
                            route="/supply-chains/pending"
                            label="Pending Client Invitations"
                            roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                        />
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>

                <ProtectedRouteLink
                    route="/shop"
                    label="Shop"
                    roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                />
                <ProtectedRouteLink
                    route="/assessments"
                    label="My Assessments"
                    roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                />
                <ProtectedRouteLink
                    route="/benefits/membership"
                    roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                />
                <ProtectedRouteLink
                    route="/company"
                    label="Company Bio"
                    roleRestriction={[ROLE_COMPANY_ADMINISTRATOR]}
                >
                    <ProtectedRouteGroup>
                        <ProtectedRouteLink
                            route="/company/info"
                            label="Company Details"
                        />
                        <ProtectedRouteLink route="/company/users" />
                        <ProtectedRouteLink route="/company/services" />
                        <ProtectedRouteLink route="/company/regions" />
                        <ProtectedRouteLink route="/company/insurances" />
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>

                <ProtectedRouteLink
                    route="/review"
                    label="Review/Assess"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                        ROLE_REGISTRATIONS_RENEWALS,
                        ROLE_ASSESSMENT_ASSESSOR,
                        ROLE_ASSESSMENT_REVIEWER,
                    ]}
                >
                    <ProtectedRouteGroup
                        roleRestriction={[
                            ROLE_ADMINISTRATOR,
                            ROLE_PROSURE_CLIENT_SERVICES,
                            ROLE_REGISTRATIONS_RENEWALS,
                            ROLE_ASSESSMENT_ASSESSOR,
                            ROLE_ASSESSMENT_REVIEWER,
                        ]}
                    >
                        <ProtectedRouteLink
                            route="/review/assessment-comments"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/review/purchased-assessments"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/review/assessments"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                                ROLE_REGISTRATIONS_RENEWALS,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/review/completed-assessments"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                                ROLE_REGISTRATIONS_RENEWALS,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/review/refunded-assessments"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/review/expiring-assessments"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/review/purchased-supplychains"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>

                <ProtectedRouteLink
                    route="/benefits"
                    label="Membership Benefits"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                        ROLE_REGISTRATIONS_RENEWALS,
                        ROLE_ASSESSMENT_ASSESSOR,
                        ROLE_ASSESSMENT_REVIEWER,
                    ]}
                >
                    <ProtectedRouteGroup label="Membership Benefits">
                        <ProtectedRouteLink
                            route="/benefits/membership"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                            ]}
                        ></ProtectedRouteLink>
                        <ProtectedRouteLink
                            route="/benefits/interest"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                            ]}
                        ></ProtectedRouteLink>
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>
                <ProtectedRouteLink
                    route="/clients"
                    label="Clients"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                        ROLE_REGISTRATIONS_RENEWALS,
                        ROLE_ASSESSMENT_ASSESSOR,
                        ROLE_ASSESSMENT_REVIEWER,
                    ]}
                >
                    <ProtectedRouteGroup label="Clients List">
                        <ProtectedRouteLink
                            route="/clients/list"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                            ]}
                        ></ProtectedRouteLink>
                        <ProtectedRouteLink
                            route="/clients/archived-list"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                            ]}
                        ></ProtectedRouteLink>
                        <ProtectedRouteLink
                            route="/clients/campaigns"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_ASSESSMENT_ASSESSOR,
                                ROLE_ASSESSMENT_REVIEWER,
                            ]}
                        ></ProtectedRouteLink>
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>
                <ProtectedRouteLink
                    route="/supply-chain"
                    label="Supply Chain"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                        ROLE_REGISTRATIONS_RENEWALS,
                        ROLE_CLIENT_ADMINISTRATOR,
                        ROLE_CLIENT_VIEW_ONLY,
                        ROLE_CLIENT_USER,
                        ROLE_ASSESSMENT_REVIEWER,
                        ROLE_ASSESSMENT_ASSESSOR,
                    ]}
                >
                    <ProtectedRouteGroup
                        roleRestriction={[
                            ROLE_ADMINISTRATOR,
                            ROLE_PROSURE_CLIENT_SERVICES,
                            ROLE_REGISTRATIONS_RENEWALS,
                            ROLE_CLIENT_ADMINISTRATOR,
                            ROLE_CLIENT_VIEW_ONLY,
                            ROLE_CLIENT_USER,
                            ROLE_ASSESSMENT_REVIEWER,
                            ROLE_ASSESSMENT_ASSESSOR,
                        ]}
                    >
                        <ProtectedRouteLink
                            route="/supply-chain/list"
                            label="List View"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_CLIENT_ADMINISTRATOR,
                                ROLE_CLIENT_VIEW_ONLY,
                                ROLE_CLIENT_USER,
                                ROLE_ASSESSMENT_REVIEWER,
                                ROLE_ASSESSMENT_ASSESSOR,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/supply-chain/analytics"
                            label="Analytics"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                                ROLE_REGISTRATIONS_RENEWALS,
                                ROLE_CLIENT_ADMINISTRATOR,
                                ROLE_CLIENT_VIEW_ONLY,
                                ROLE_CLIENT_USER,
                                ROLE_ASSESSMENT_REVIEWER,
                                ROLE_ASSESSMENT_ASSESSOR,
                            ]}
                        />
                        {getCafmStatsLink()}
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>
                <ProtectedRouteLink
                    route="/reports"
                    label="Reports"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                        ROLE_REGISTRATIONS_RENEWALS,
                        ROLE_CLIENT_ADMINISTRATOR,
                        ROLE_CLIENT_VIEW_ONLY,
                        ROLE_CLIENT_USER,
                    ]}
                />
                <ProtectedRouteLink
                    route="/admin"
                    label="Administration"
                    roleRestriction={[
                        ROLE_ADMINISTRATOR,
                        ROLE_PROSURE_CLIENT_SERVICES,
                    ]}
                >
                    <ProtectedRouteGroup
                        label="Administration"
                        roleRestriction={[
                            ROLE_ADMINISTRATOR,
                            ROLE_PROSURE_CLIENT_SERVICES,
                        ]}
                    >
                        <ProtectedRouteLink
                            route="/admin/assessment-types?order=name"
                            roleRestriction={[ROLE_ADMINISTRATOR]}
                        />
                        <ProtectedRouteLink
                            route="/admin/certification-memberships?order=name"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/discounts"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/email-templates"
                            roleRestriction={[ROLE_ADMINISTRATOR]}
                        />
                        <ProtectedRouteLink
                            route="/admin/integrations"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/questions?order=createdAt"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/question-categories?order=name"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/regions"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/reports"
                            roleRestriction={[ROLE_ADMINISTRATOR]}
                        />
                        <ProtectedRouteLink
                            route="/admin/services"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                        <ProtectedRouteLink
                            route="/admin/users?order=forename"
                            roleRestriction={[
                                ROLE_ADMINISTRATOR,
                                ROLE_PROSURE_CLIENT_SERVICES,
                            ]}
                        />
                    </ProtectedRouteGroup>
                </ProtectedRouteLink>
            </ul>
        </nav>
    );
};

ProsureMenu.propTypes = {
    show: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['mobile', 'desktop']).isRequired,
};

export default memo(ProsureMenu);
