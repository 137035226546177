import { STATUS_DECLINED } from 'constants/supplyChain';
import { parseDateFormat } from 'helpers/helpers';
import { CancelReasonMappings } from 'interface/CancelSupplyChain';
import {
    CLIENT_REMOVED_STATUS,
    REMOVED_STATUS,
    SupplyChain,
} from 'interface/SupplyChain';
import { DateTime } from 'luxon';
import { RouteComponentProps } from 'react-router';

interface SupplyChainDescriptionProps extends RouteComponentProps {
    supplyChain: SupplyChain;
    showRemoveButton?: boolean;
    removedWhenExpiredButton?: any;
    canLeaveButton?: any;
}

const SupplyChainDescription = (props: SupplyChainDescriptionProps) => {
    const {
        supplyChain,
        showRemoveButton,
        removedWhenExpiredButton,
        canLeaveButton,
    } = props;

    const getCancelledMessage = () => {
        const cancelledAt = parseDateFormat(
            supplyChain.cancelledAt,
            'Do MMM YYYY '
        );

        const acceptedAt = parseDateFormat(
            supplyChain.acceptedAt,
            'Do MMMM, YYYY'
        );

        const { cancelledBy, cancelledReason } = supplyChain;
        let reason = 'Unknown';

        if (typeof cancelledReason === 'number') {
            reason = CancelReasonMappings[cancelledReason];
        }

        return (
            <>
                <span className="error">
                    Cancelled at {cancelledAt} by {cancelledBy} with reason{' '}
                    {reason}
                </span>
                <div>{getJoinedAtMessage(acceptedAt)}</div>
            </>
        );
    };

    const getJoinedAtMessage = (acceptedAt: string) => {
        if (supplyChain.originalAcceptedAtDate) {
            const originalAcceptedAtDate = parseDateFormat(
                supplyChain.originalAcceptedAtDate,
                'Do MMMM, YYYY'
            );
            return `Joined supply chain ${spCatName} on ${originalAcceptedAtDate}, renewed on ${acceptedAt}`;
        } else if (acceptedAt)
            return `Joined supply chain ${spCatName} on ${acceptedAt}`;

        return '';
    };

    let message = null;

    if (!supplyChain) {
        return message;
    }
    const spCatName = supplyChain.serviceProviderCategoryName
        ? ` as: ${supplyChain.serviceProviderCategoryName}`
        : '';
    const createdAt = parseDateFormat(
        supplyChain.createdAt,
        'Do MMM YYYY - HH:mma'
    );

    if (supplyChain.cancelledAt) {
        return getCancelledMessage();
    }

    if (supplyChain.acceptedAt) {
        // Default to legacy message in case of any supply chains in the system that haven't got a category like
        // dev fixtures
        const acceptedAt = parseDateFormat(
            supplyChain.acceptedAt,
            'Do MMMM, YYYY'
        );

        let joinedAtMessage,
            expiresMessage,
            removeWhenExpiredMessage,
            removeWhenExpiredButton,
            expiringSoonMessage,
            clientRemovedMessage;

        joinedAtMessage = getJoinedAtMessage(acceptedAt);

        if (supplyChain.expiresOn) {
            const expiresOn = parseDateFormat(
                supplyChain.expiresOn,
                'Do MMMM, YYYY'
            );
            expiresMessage = `, expiring ${expiresOn}.`;

            if (
                DateTime.now() >
                DateTime.fromISO(supplyChain.expiresOn).endOf('day')
            ) {
                expiringSoonMessage = (
                    <span className="leaving-message">
                        Expired from supply chain.{` `}
                    </span>
                );

                joinedAtMessage = '';
                expiresMessage = `Expiry ${expiresOn}.`;
            } else if (
                DateTime.now().plus({ days: 61 }).startOf('day') >
                DateTime.fromISO(supplyChain.expiresOn).endOf('day')
            ) {
                if (supplyChain.isBeingRenewed) {
                    expiringSoonMessage = (
                        <span className="renewing-message">
                            Expiring soon with renewal invitation sent.{`  `}
                        </span>
                    );
                } else {
                    expiringSoonMessage = (
                        <span className="renewing-message">
                            Expiring soon.{`  `}
                        </span>
                    );
                }
            } else {
                expiresMessage = `, expiry ${expiresOn}.`;
            }

            removeWhenExpiredButton =
                showRemoveButton && supplyChain.isBeingRenewed === false
                    ? removedWhenExpiredButton
                    : '';

            removeWhenExpiredMessage =
                supplyChain.status === REMOVED_STATUS ? (
                    <span className="leaving-message">
                        Removed by the service provider.{` `}
                    </span>
                ) : supplyChain.removedWhenExpired ? (
                    <span className="leaving-message">
                        Marked for removal on the{` `}
                        {supplyChain.removedAt &&
                            parseDateFormat(
                                supplyChain.removedAt,
                                'Do MMM YYYY'
                            ) + ` by `}
                        {supplyChain.removedByForename &&
                            supplyChain.removedByForename + ` `}
                        {supplyChain.removedBySurname &&
                            supplyChain.removedBySurname + ` - `}
                        {supplyChain.removeReason && supplyChain.removeReason}
                        {`. `}
                    </span>
                ) : (
                    ''
                );

            clientRemovedMessage =
                supplyChain.status === CLIENT_REMOVED_STATUS ? (
                    <span className="leaving-message">
                        Client Archived - Supply chain archived at{' '}
                        {parseDateFormat(supplyChain.archivedAt, 'Do MMM YYYY')}
                        {`. `}
                    </span>
                ) : (
                    ''
                );
        }

        message = (
            <span>
                {clientRemovedMessage}
                {removeWhenExpiredMessage}
                {expiringSoonMessage}
                {joinedAtMessage}
                {expiresMessage}
                {removeWhenExpiredButton}
                {canLeaveButton}
            </span>
        );
    } else if (supplyChain.isRenewing === true) {
        message = (
            <>Reinvited to join supply chain {`${spCatName} on ${createdAt}`}</>
        );

        //is it expired?
        if (
            DateTime.now() >
            DateTime.fromISO(supplyChain.expiresOn).endOf('day')
        ) {
            message = (
                <>
                    <span className="leaving-message">
                        Expired from supply chain
                    </span>
                    , but reinvited to join supply chain{' '}
                    {`${spCatName} on ${createdAt}`}
                </>
            );
        }

        // is it a decined renewal?
        if (supplyChain.declinedAt) {
            const declinedAt = parseDateFormat(
                supplyChain.declinedAt,
                'Do MMM YYYY - HH:mma '
            );
            message = (
                <>
                    Reinvited to join supply chain{' '}
                    {`${spCatName} on ${createdAt}`}.{' '}
                    <span className="leaving-message">
                        Declined to re-join supply chain
                        {` `}
                        at {declinedAt}
                        with reason {supplyChain.declinedReason}{' '}
                    </span>
                </>
            );
        }
    } else if (supplyChain.status === STATUS_DECLINED) {
        const declinedAt = parseDateFormat(
            supplyChain.declinedAt,
            'Do MMM YYYY - HH:mma '
        );
        message = (
            <>
                <span className="leaving-message">
                    Declined to join supply chain {spCatName}
                    {` `}
                    at {declinedAt}
                    {supplyChain.declinedReason
                        ? `with reason ${supplyChain.declinedReason}`
                        : ''
                    }
                </span>
            </>
        );
    } else {
        message = (
            <>Invited to join supply chain {`${spCatName} on ${createdAt}`}</>
        );
    }

    return <>{message}</>;
};

export default SupplyChainDescription;
