import { PopoverPosition } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { DateTime } from "luxon";

import { BadgeData } from "interface/Badge";
import { InstanceService } from "service";

import './index.scss';

interface BadgeProps { 
    badge: BadgeData;
}

const Badge = (props: BadgeProps) => {
    const { badge } = props;
    const {
        _links,
        assessmentTypeInstanceStatus,
        completedAt,
        expiryDate,
        lastStatusUpdate,
    } = badge;

    const getAssessmentText = () => {
        let text = _links['assessment-type'].name;

        if (expiryDate) {
            text += ` expires ${DateTime.fromISO(expiryDate).toFormat('DD')}`;
        }

        return text;
    };

    const getImageUrl = () => (
        process.env.REACT_APP_BASE_URL + _links.file.publicUrl
    );

    const getPopupContent = () => {
        const statusText = InstanceService.getStatusText(assessmentTypeInstanceStatus);
        const lastUpdated = DateTime.fromISO(lastStatusUpdate).toLocaleString(DateTime.DATETIME_SHORT);

        return (
            <>
                <strong>
                    {_links['assessment-type'].name}
                </strong>
                <br/>
                {statusText ? `${statusText} at ${lastUpdated}` : ''}
            </>
        );
    };

    return (
        <Tooltip2
            content={getPopupContent()}
            position={PopoverPosition.TOP}
            className="ms-2 contact-tooltip"
        >
            <div className={!completedAt ? 'black-and-white' : ''}>
                <img
                    className="logo"
                    onDragStart={(e) => e.preventDefault()}
                    alt={_links['assessment-type'].name + "badge"}
                    title={getAssessmentText()}
                    src={getImageUrl()}
                ></img>
            </div>
        </Tooltip2>
    );
}

export default Badge;
